<template>
  <cs-page>
    <div class="text-center" style="padding-top: 300px">
      <a-spin size="large" />
      <br /><br />
      正在跳转页面...
    </div>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    const { path } = this.$route.query;
    if (path) {
      this.$router.replace(path);
    } else {
      this.$router.replace("/");
    }
  },
  methods: {},
};
</script>